<template>
  <section >
    <h2 class="titulo-links-escolha">Enviar arquivo</h2>

    <pm-Card>
      <template v-slot:content>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-12">
             <h3 class="titulo-links-escolha">Selecione o arquivo para enviar</h3>
          </div>
          <div class="field col-12 md:col-6">
            <label>Digite o nome do(s) arquivo(s)</label>
            <pm-InputText  v-model="alunosInfo.nome" />
          </div>

          <div class="field col-12 md:col-12">
            <label for="arquivo">Selecione o(s) arquivo(s):</label>
            <va-file-upload
              v-model="alunosInfo.arquivo"
              file-types=".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.pdf,.PDF,.doc,.DOC,.docx,.DOCX"
            />
          </div>
          <div class="field col-12 md:col-2">
            <pm-Button label="Voltar"  class="p-button-danger" icon="pi pi-angle-left" iconPos="left" @click="voltar()" />
          </div>

          <div class="field col-12 md:col-2" v-if="alunosInfo.arquivo != null && alunosInfo.arquivo != '' ">

            <pm-Button label="Cadastrar arquivo"
              :disable="botaoDisabled"
              @click="cadastrarArquivo"
            />

          </div>
        </div>
      </template>
    </pm-Card>

    <br>

    <div class="pm-card">
      <pm-DataTable :value="arquivoSelecionado"   dataKey="id" :rowHover="true" filterDisplay="menu"
         responsiveLayout="scroll">

          <pm-Column field="arquivo" sortable header="Arquivo" style="font-size: 14px;vertical-align:middle !important">
            <template #body="{data}">
              <p>{{data.nome}}</p>
            </template>
          </pm-Column>

          <pm-Column field="all" headerStyle="width: 4rem; text-align: center"
             bodyStyle="text-align: center; overflow: visible;width: 357px;vertical-align:middle !important">
            <template #body="{data}">
             <pm-Button title="Visualizar arquivo" type="button"  @click="visualizarArquivoModal(data.arquivo)" label="Visualizar documento"
                      icon="pi pi-file" class="p-button-primary p-button-sm btn-color;table_style"
              />
              <pm-Button style="margin-left:6px;" label="Apagar"
                icon="pi pi-times-circle" class="p-button-danger p-button-sm btn-color" title="Apagar"
                @click="deletarArquivo(data.id)"
              />
            </template>
          </pm-Column>

      </pm-DataTable>
    </div>
    <pm-Dialog position="topright" header="Documento" v-model:visible="displayMaximizableEdit" :style="{width: '50vw', height: '50vw'}" :maximizable="true">
        <div class="col-md-12">
            <iframe :src="arquivosView" width="100%" height="1000px"></iframe>
        </div>
    </pm-Dialog>
  </section >
</template>

<script>
import axios from 'axios';
import { Aluno } from "@/class/alunos";
import store_token_info from "@/store/store_token_info.js";

export default {
  props: {
    id:{},
    pg:{}
  },
  components: {

  },
  name: 'form-elements',
  data () {
    const columns = [
      {key: 'nome', sortable: true, label: "Nome do aluno" },
      {key: 'arquivo', sortable: true },
      { key: 'id', label:'Ação'},
    ]
    return {
      arquivosView:'',
      displayMaximizableEdit:false,
      botaoDisabled:false,
      pathDoSite: axios.defaults.baseURL.replace('api/v1',''),
      arquivoSelecionado:[],
      columns,
      perPage: 20,
      currentPage: 1,
      alunosInfo: {
        nome:'',
        arquivo:'',
        user_id: store_token_info.usuario_logado,
      },
    }
  },
  computed: {
    pages () {
      return (this.perPage && this.perPage !== '0')
          ? Math.ceil(this.arquivoSelecionado.length / this.perPage)
          : this.arquivoSelecionado.length
    },
  },
  methods: {
    visualizarArquivoModal(arquivo) {
      this.arquivosView = arquivo;
      this.displayMaximizableEdit = true;
    },
    voltar(){
      this.$router.push({name: 'alunosAll', params: { pg: this.pg }});
    },
    async cadastrarArquivo(){
      try {
       this.botaoDisabled = true;
        let arquivoNumeracao = 1;
        var currentdate = new Date();
        var datetime = currentdate.getFullYear() + "-"
                + (currentdate.getMonth()+1)  + "-"
                + currentdate.getDate() + "  "
                + currentdate.getHours() + ":"
                + currentdate.getMinutes() + ":"
                + currentdate.getSeconds();
        for (var i = 0; i < this.alunosInfo.arquivo.length; i++) {
          let formData = new FormData();
          formData.append('documento_auxiliar', this.alunosInfo.arquivo[i]);
          formData.append('nome', this.alunosInfo.nome+'_'+String(arquivoNumeracao++));
          formData.append('local', "arquivoAdicionalAluno");
          formData.append('data', datetime);
          let data1 = await Aluno.cadastrarArquivo(this.id, formData);
        }
        this.botaoDisabled = false;
        this.$vaToast.init({
          message: "Arquivo cadastrado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'bottom-left',
          duration: 3500,
          fullWidth: false,
          color: 'success'
        });
        this.alunosInfo.arquivo = '';
        this.buscarArquivo();
      } catch (e) {
        this.$vaToast.init({
          message: "Problema ao cadastrar o arquivo",
          iconClass: 'fa-star-o',
          position: 'bottom-left',
          duration: 3500,
          fullWidth: false,
          color: 'danger'
        });
      }
    },
    async buscarArquivo() {
      this.alunosInfo.nome = '';
      this.alunosInfo.arquivo = '';
      let data = await Aluno.buscarArquivoAluno(this.id, "arquivoAdicionalAluno");
      try {
          this.arquivoSelecionado = [];

          for (const el of data.data) {
            let novo = {
              id:el.id,
              nome: el.nome,
              path: el.path,
              aluno_id: el.aluno_id,
              arquivo: this.pathDoSite + 'storage/'+el.path,
            }
            this.arquivoSelecionado.push(novo);
          }
      }
      catch(e) {

      }

    },
    async deletarArquivo(idFile) {
      try {
        if (confirm("Você realmente deseja apagar o arquivo?")) {
           let novo = {
            aluno_id: this.id,
            id: idFile
          }
          let data = await Aluno.deletarArquivo(novo);
          this.$vaToast.init({
            message: "Arquivo apagado com sucesso!",
            iconClass: 'fa-star-o',
            position: 'bottom-left',
            duration: 3500,
            fullWidth: false,
            color: 'success'
          });
          this.buscarArquivo();
        }
      }
      catch(e) {
          this.$vaToast.init({
          message: "Problema ao apagar o arquivo!",
          iconClass: 'fa-star-o',
          position: 'bottom-left',
          duration: 3500,
          fullWidth: false,
          color: 'danger'
        });
      }
    },
  },
  mounted() {
    if (this.id == null) {
      this.$router.push({name: 'alunosAll', params: { pg: this.pg }});
    }
    else {
      this.buscarArquivo();
    }
  }
}
</script>

<style>
  .row.row-inside {
    max-width: none;
  }

  .form-control:focus {
    background-color: #f6f7f6;
    border-color: #673ab7;
    box-shadow: none;
  }
  .form-control {
    background-color: #f6f7f6;
    border: 1px solid #f6f6f6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  .va-input-wrapper, fieldset {
    margin-bottom: 0.5rem;
  }
  .va-file-upload .va-file-upload__field .va-file-upload__field__button {
    border-radius: 4px!important;
  }

</style>
